import React from "react";
import styles from "./Insights.module.scss";
import Button from "components/core/Button/Button";
import {
  ContentfulContentType,
  InsightDocument,
} from "@tiicker/util/lib/contentful/types";
import { useContentfulEntries } from "source/contentful/provider";
import { HomeInsightsQuery } from "@tiicker/util/lib/contentful/queries";
import InsightsMosaic from "./InsightsMosaic/InsightsMosaic";

const Insights = () => {
  const insightsQuery = useContentfulEntries<InsightDocument>(
    ContentfulContentType.Article,
    {
      query: HomeInsightsQuery(),
    }
  );

  return (
    <div className={styles.Insights}>
      <div className="container">
        <div className={styles.Insights__content}>
          <div className={styles.Insights__headerContainer}>
            <h2>Insights</h2>
            <p>
              Market insights, shareholder news, and exclusive content from the
              TiiCKER team.
            </p>
            <Button
              className={styles.Insights__CTAButtonDesktop}
              type="secondary"
              href="/insights"
            >
              View All Insights
            </Button>
          </div>
          {insightsQuery.state === "DONE" && (
            <div className={styles.Insights__canvas}>
              <InsightsMosaic
                insights={insightsQuery.result.items}
                includes={insightsQuery.result.includes}
              />
            </div>
          )}
          <Button
            className={styles.Insights__CTAButtonMobile}
            type="secondary"
            href="/insights"
          >
            View All Insights
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Insights;
