import {
  ContentSection,
  PageSection,
} from "@tiicker/util/lib/contentful/types";
import Button from "components/core/Button/Button";
import { Entry } from "contentful";
import { Fragment } from "react";
import styles from "./WhatIsTiiCKER.module.scss";

type Props = {
  whatIsTiiCKER: Entry<PageSection>;
  content: Entry<ContentSection>[] | undefined;
  includes?: any;
};

const WhatIsTiiCKER = (props: Props) => {
  return (
    <div className={`container ${styles.WhatIsTiiCKER__container}`}>
      <div className={styles.WhatIsTiiCKER}>
        {props.content?.map((section) => (
          <Fragment key={`what-is-tiicker-${section.sys.id}`}>
            <h5 className={styles.WhatIsTiiCKER__title}>
              {section.fields.displayTitle}
            </h5>
            <p className={styles.WhatIsTiiCKER__text}>
              {section.fields.textContent}
            </p>
            <Button type="inline" arrow href={section.fields.linkLocation}>
              {section.fields.linkText}
            </Button>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default WhatIsTiiCKER;
