import * as React from "react";
import { GetStaticProps } from "next";
import { getContentfulClient } from "@tiicker/util/lib/contentful/client";
import {
  ContentfulContentType,
  ContentfulKey,
  GenericMarketingPage,
  PageSection,
  PageSectionKey,
  BrandDetailDocument,
  PerkOfTheWeekDocument,
  FAQDocument,
} from "@tiicker/util/lib/contentful/types";
import { Entry, EntryCollection } from "contentful";
import {
  ContentSections,
  getImageReference,
  getItemReference,
  loadContentSections,
} from "@tiicker/util/lib/contentful/helpers";
import { compact } from "lodash";
import HomeHowItWorks from "components/Home/HomeHowItWorks/HomeHowItWorks";
import WhatIsTiiCKER from "components/Home/WhatIsTiiCKER/WhatIsTiiCKER";
import HomeBrandSearch from "components/Home/HomeBrandSearch/HomeBrandSearch";
import HomeFAQ from "components/Home/HomeFAQ/HomeFAQ";
import HomeFeaturedPerks from "components/Home/HomeFeaturedPerks/HomeFeaturedPerks";
import NavigationBar from "components/Global/Navigation/NavigationBar/NavigationBar";
import StartEarningPerksMadi from "components/StartEarningPerksMadi/StartEarningPerksMadi";
import ChooseYourDevice from "components/ChooseYourDevice/ChooseYourDevice";
import Insights from "components/Insights/Insights";
import GenericMetaTags from "components/MetaTags/GenericMetaTags";
import HeroSection from "components/Home/HeroSection/HeroSection";
import FooterV2 from "components/FooterV2/FooterV2";
import FeaturedPerks from "components/Home/FeaturedPerks/FeaturedPerks";
import ColorWrapper from "components/Home/ColorWrapper/ColorWrapper";
import * as DateIso from "@tiicker/util/lib/date-iso";
import { useFetchUser } from "source/hooks";
import LoadingBlock from "components/Loading/LoadingBlock";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import Container from "components/core/Layout/Container/Container";
import PerkAlert from "components/Dashboard/PerkAlert/PerkAlert";

interface Props {
  homepage: Entry<GenericMarketingPage> | undefined;
  featuredPerks: Entry<PageSection> | undefined;
  howItWorks: Entry<PageSection> | undefined;
  whatIsTiiCKER: Entry<PageSection> | undefined;
  brandSearch: Entry<PageSection> | undefined;
  joinNowCTA: Entry<PageSection> | undefined;
  perkAlert: Entry<PageSection> | undefined;
  chooseYourDevice: Entry<PageSection> | undefined;
  faq: Entry<PageSection> | undefined;
  perksOfTheWeek: EntryCollection<PerkOfTheWeekDocument> | undefined;
  contentSections: ContentSections;
  homepageEntries: EntryCollection<GenericMarketingPage>;
  faqEntries: Entry<FAQDocument>[];
  preloadedBrands: EntryCollection<BrandDetailDocument>;
}

const Index = (props: Props) => {
  const userBundle = useFetchUser();
  const router = useRouter();

  const homepageTitle =
    props.homepage?.fields.displayTitle ||
    "Get <span>perks</span> from your favorite <span>stocks</span>";
  const homepageCta =
    getItemReference(props.homepage?.fields.callToAction, props.homepageEntries)
      ?.fields.linkText || "Join Now";

  // Check for auth0 cookie without messing with static generation of this page...
  const cookie = Cookies.get();
  const authKey = cookie
    ? Object.keys(cookie).find((key) => key.startsWith("auth0"))
    : undefined;
  const isAuthed = authKey ? cookie[authKey] === "true" : false;
  if (isAuthed) {
    router.push("/dashboard");
    return (
      <Container>
        <LoadingBlock />
      </Container>
    );
  }

  return (
    <>
      {props.homepage && (
        <GenericMetaTags pageContent={props.homepage.fields} />
      )}
      <ColorWrapper>
        <NavigationBar />

        <HeroSection title={homepageTitle} ctaText={homepageCta} />
        {props.featuredPerks && (
          <FeaturedPerks
            featuredPerkData={props.featuredPerks}
            loadedBrands={props.preloadedBrands}
            content={props.contentSections.sections[props.featuredPerks.sys.id]}
            contentIncludes={props.contentSections.includes}
          />
        )}
      </ColorWrapper>

      {props.brandSearch && (
        <HomeBrandSearch
          brandSearch={props.brandSearch}
          content={props.contentSections.sections[props.brandSearch.sys.id]}
          includes={props.homepageEntries.includes}
        />
      )}
      <Container>
        <PerkAlert
          showButton
          location="Home"
          largeMargin
          title={
            props.perkAlert ? props.perkAlert.fields.displayTitle : undefined
          }
          description={
            props.perkAlert ? props.perkAlert.fields.description : undefined
          }
        />
      </Container>
      {props.whatIsTiiCKER && (
        <WhatIsTiiCKER
          whatIsTiiCKER={props.whatIsTiiCKER}
          content={props.contentSections.sections[props.whatIsTiiCKER.sys.id]}
          includes={props.homepageEntries.includes}
        />
      )}
      {props.howItWorks && (
        <HomeHowItWorks
          howItWorks={props.howItWorks}
          content={props.contentSections.sections[props.howItWorks.sys.id]}
          includes={props.homepageEntries.includes}
        />
      )}
      <HomeFeaturedPerks 
        perksOfTheWeek={props.perksOfTheWeek} 
        loadedBrands={props.preloadedBrands}
        />
      <Insights />
      {props.chooseYourDevice && (
        <ChooseYourDevice
          entry={props.chooseYourDevice}
          content={
            props.contentSections.sections[props.chooseYourDevice.sys.id]
          }
          includes={props.homepageEntries.includes}
        />
      )}
      {props.faqEntries && props.faq && (
        <HomeFAQ
          header={props.faq.fields.displayTitle}
          content={props.faqEntries}
        />
      )}
      {props.joinNowCTA && (
        <StartEarningPerksMadi
          page={props.joinNowCTA}
          includes={props.contentSections.includes}
        />
      )}
      <FooterV2 />
    </>
  );
};

export const getStaticProps: GetStaticProps<Props> = async () => {
  const client = getContentfulClient("contentful");

  const homepage = await client.getEntries<GenericMarketingPage>({
    content_type: ContentfulContentType.GenericMarketingPage,
    ["fields.key"]: ContentfulKey.Homepage,
  });

  const pageRecord = homepage.items.length > 0 ? homepage.items[0] : null;

  const pageSections = compact(
    pageRecord?.fields.pageSections?.map((section) =>
      getItemReference(section, homepage.includes)
    )
  );

  const brandSearch = pageSections.find(
    (x) => x.fields.sectionKey === PageSectionKey.HomeBrandSearch
  );
  const howItWorks = pageSections.find(
    (x) => x.fields.sectionKey === PageSectionKey.HomeHowItWorks
  );
  const whatIsTiiCKER = pageSections.find(
    (x) => x.fields.sectionKey === PageSectionKey.WhatIsTiiCKER
  );
  const faq = pageSections.find(
    (x) => x.fields.sectionKey === PageSectionKey.HomeFaq
  );

  const device = pageSections.find(
    (x) => x.fields.sectionKey === PageSectionKey.ChooseYourDevice
  );

  const cta = pageSections.find(
    (x) => x.fields.sectionKey === PageSectionKey.JoinNowCTA
  );
  const perkAlert = pageSections.find(
    (x) => x.fields.sectionKey === PageSectionKey.HomeTiiPerk
  );

  const today = DateIso.today();
  const perksOfTheWeek = await client.getEntries<PerkOfTheWeekDocument>({
    content_type: ContentfulContentType.PerkOfTheWeek,
    ["fields.startDate[lte]"]: today,
    order: "-fields.startDate",
    limit: "15",
  });

  const contentSections = await loadContentSections(client, pageSections);

  // Load featured perks and associated brands
  const featuredPerks = pageSections.find(
    (x) => x.fields.sectionKey === PageSectionKey.HomeFeaturedPerks
  );

  const perks = (
    featuredPerks ? contentSections.sections[featuredPerks.sys.id] : []
  )[0]?.fields.perks;
  const loadedPerks = compact(
    perks?.map((p) => getItemReference(p, contentSections.includes)) || []
  );
  const brandIdstoLoad = loadedPerks.map((p) => p.fields.brand.sys.id);
  const loadedBrands = await client.getEntries<BrandDetailDocument>({
    content_type: ContentfulContentType.BrandDetail,
    ["sys.id[in]"]: brandIdstoLoad.join(","),
  });

  const faqContentSections = faq ? contentSections.sections[faq.sys.id] : [];
  const faqSection =
    faqContentSections.length > 0 ? faqContentSections[0] : undefined;
  const faqEntries = compact(
    faqSection?.fields.faqs
      ? faqSection.fields.faqs.map((faq) =>
          getItemReference(faq, contentSections.includes)
        )
      : []
  );

  return {
    props: {
      homepage: pageRecord || undefined,
      featuredPerks,
      preloadedBrands: loadedBrands,
      howItWorks,
      whatIsTiiCKER,
      brandSearch,
      contentSections,
      joinNowCTA: cta,
      faq,
      chooseYourDevice: device,
      homepageEntries: homepage,
      perksOfTheWeek,
      faqEntries,
      perkAlert: perkAlert ?? undefined,
    },
    revalidate: 100,
  };
};

export default Index;
