import {
  getImageReference,
  parseIncludesForItem,
} from "@tiicker/util/lib/contentful/helpers";
import {
  BrandDetailDocument,
  ContentfulContentType,
  InsightDocument,
} from "@tiicker/util/lib/contentful/types";
import { Entry } from "contentful";
import React from "react";
import styles from "./InsightCard.module.scss";
import Card from "components/core/Card/Card";
import * as DateIso from "@tiicker/util/lib/date-iso";
import { useContentfulEntries } from "source/contentful/provider";
import classNames from "classnames";

type Props = {
  type: "row" | "column" | "block" | "halfColumn";
  insight: Entry<InsightDocument>;
  includes: any;
  showDate?: boolean;
  showDescription?: boolean;
  className?: string;
  featuredCard?: boolean;
};

const InsightCard = (props: Props) => {
  const image = getImageReference(
    props.insight.fields.heroImage,
    props.includes
  );

  const brands = useContentfulEntries<BrandDetailDocument>(
    ContentfulContentType.BrandDetail,
    {
      query: {
        content_type: ContentfulContentType.BrandDetail,
        "fields.tickerSymbol[in]": props.insight.fields
          .relatedStockTickerSymbols
          ? props.insight.fields.relatedStockTickerSymbols.join(",")
          : "",
      },
    }
  );

  if (!image) {
    return <></>;
  }

  return (
    <div className={classNames(styles.card, props.className)}>
      <Card
        layout={props.type}
        image={image.fields.file.url}
        title={props.insight.fields.title}
        badge={props.insight.fields.category}
        description={
          props.showDescription ? props.insight.fields.description : undefined
        }
        date={
          props.showDate
            ? DateIso.formatLongMonthDayYear(
                DateIso.toIsoDate(props.insight.fields.publishedDate)
              )
            : undefined
        }
        url={`/insights/${props.insight.fields.slug}`}
        sliderImages={
          props.type === "halfColumn" && brands.state === "DONE"
            ? brands.result.items.map((brand) => {
                const logo = getImageReference(
                  brand.fields.companyLogoDark,
                  brands.result.includes
                );

                return logo?.fields.file.url ?? "";
              })
            : undefined
        }
        featuredCard={props.featuredCard}
      />
    </div>
  );
};

export default InsightCard;
