import React, { useEffect, useRef, useState } from "react";
import {
  PageSection,
  ContentSection,
  BrandDetailDocument,
} from "@tiicker/util/lib/contentful/types";
import { Entry, EntryCollection } from "contentful";
import styles from "./FeaturedPerks.module.scss";
import {
  getImageReference,
  getItemReference,
} from "@tiicker/util/lib/contentful/helpers";
import { compact, sum } from "lodash";
import { useCurrentWidth } from "source/hooks";
import { motion } from "framer-motion/dist/framer-motion";
import PerkCard, { PerkCardProps } from "components/core/PerkCard/PerkCard";

type Props = {
  featuredPerkData: Entry<PageSection>;
  loadedBrands: EntryCollection<BrandDetailDocument>;
  content: Entry<ContentSection>[] | undefined;
  contentIncludes?: any;
};

const FeaturedPerks = (props: Props) => {
  const content =
    props.content && props.content.length > 0 ? props.content[0] : null;
  const perks = compact(
    (content?.fields.perks || []).map((p) => {
      const loadedPerk = getItemReference(p, props.contentIncludes);
      const loadedBrand = props.loadedBrands.items.find(
        (b) => b.sys.id === loadedPerk?.fields.brand.sys.id
      );

      if (!loadedPerk || !loadedBrand) {
        console.log("Perk or brand not found..");
        return null;
      }

      const loadedPerkImage = getImageReference(
        loadedPerk.fields.image,
        props.contentIncludes
      );

      const brandLogo = getImageReference(
        loadedBrand.fields.companyLogoDark,
        props.loadedBrands.includes
      );

      if (!loadedPerkImage?.fields.file.url || !brandLogo?.fields.file.url) {
        return null;
      }

      const conformedPerk: PerkCardProps = {
        id: loadedPerk.sys.id,
        title: loadedPerk.fields.title,
        imageUrl: loadedPerkImage.fields.file.url,
        brandLogo: brandLogo.fields.file.url,
        tickerSymbol: loadedBrand.fields.tickerSymbol,
      };

      return conformedPerk;
    })
  );

  const screenWidth = useCurrentWidth();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [dragOffsetLimit, setDragOffsetLimit] = useState(-500);
  const sliderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setInterval(() => setHasLoaded(true), 2000);
  }, []);

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    const slides = sliderRef.current.querySelectorAll(".app-data-slide");
    const widths = Array.from(slides).map(
      (el) => el.getBoundingClientRect().width
    );
    const gapsBetweenSlides = (slides.length - 1) * 24;
    const total = sum(widths) + gapsBetweenSlides;
    setDragOffsetLimit((total * -1) / 2);
  }, [screenWidth, sliderRef.current]);

  return (
    <div className={styles.outerContainer}>
      {screenWidth <= 1100 ? (
        <div className={styles.innerContainer} ref={sliderRef}>
          {perks.slice(0, 4).map((p) => (
            <PerkCard
              className="app-data-slide"
              noAnimate={hasLoaded}
              key={`featured-perk-${p.id}`}
              {...p}
            />
          ))}
        </div>
      ) : (
        <div className={styles.innerContainer}>
          {perks.slice(0, 4).map((p) => (
            <PerkCard
              data-slide
              className="app-data-slide"
              noAnimate={hasLoaded}
              key={`featured-perk-${p.id}`}
              {...p}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FeaturedPerks;
