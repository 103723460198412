import classNames from "classnames";
import Button from "components/core/Button/Button";
import React from "react";
import { useAuth } from "source/hooks";
import styles from "./HeroSection.module.scss";

type Props = { title: string; ctaText: string };

const HeroSection = (props: Props) => {
  const auth = useAuth();

  const lineSplit = 4;
  const splitBySpace = props.title.split(" ");
  const firstLine = splitBySpace.slice(0, lineSplit).join(" ");
  const secondLine = splitBySpace.slice(lineSplit).join(" ");

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <h1 className={styles.title}>
          <div 
            className={styles.titleLine}
            dangerouslySetInnerHTML={{
              __html: firstLine,
            }}
          />
          <div 
            className={classNames(styles.titleLine, styles.titleLine_Delayed)}
            dangerouslySetInnerHTML={{
              __html: secondLine,
            }}
          />
        </h1>
      </div>
      <Button onClick={() => auth.smsSignUp()} className={styles.button} arrow>
        {props.ctaText}
      </Button>
    </div>
  );
};

export default HeroSection;
