import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import {
  BLOCKS,
  Document,
  INLINES,
  TopLevelBlock,
} from "@contentful/rich-text-types";
import * as React from "react";
import ContentfulInlineTable from "./ContentfulInlineTable/ContentfulInlineTable";
import { ContentfulContentType } from "@tiicker/util/lib/contentful/types";

import { parseTextForTickerSymbol } from "source/ui-utils";
import getBaseUrl from "@tiicker/util/lib/get-base-url";

const OPTIONS: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (x) => {
      if (!x.content) {
        return <> </>;
      }

      // name of contentType in contentful
      const targetType = x.data?.target?.sys?.contentType?.sys?.id;

      const data = x.data?.target?.fields;

      switch (targetType) {
        case ContentfulContentType.InlineTable:
          return <ContentfulInlineTable contentfulTable={data} />;

        default:
          return <p>{targetType} not implemented</p>;
      }
    },
    [INLINES.ENTRY_HYPERLINK]: (x) => {
      if (!x.content) {
        return <> </>;
      }

      // id of destination of link in contentful
      const target = x.data?.target?.sys?.id;

      // name of contentType in contentful
      const targetType = x.data?.target?.sys?.contentType?.sys?.id;
      //console.log(`${targetType}/${target}`);

      const document: Document = {
        data: x.data,
        nodeType: BLOCKS.DOCUMENT,

        // The documentToReactComponents wants us to provide a block-level
        // component (e.g. paragraph) but the actual implementation doesn't seem
        // to care. By doing this, we can easily render link text that includes
        // formatting and other rich text.
        content: x.content as TopLevelBlock[],
      };

      // NOTE: This is when a rich text editor links to another entry in
      // contentful. This should be implemented on a case-by-case basis
      // depending on the desired link to the linked entity.
      return (
        <a href="/implement-me">
          {documentToReactComponents(document, OPTIONS)}
        </a>
      );
    },
    [INLINES.HYPERLINK]: (node, children) => {
      if (!node.content) {
        return <> </>;
      }

      const baseUrl = getBaseUrl().href;
      if (!node.data?.uri.startsWith(baseUrl)) {
        return (
          <a href={node.data.uri} target="_blank">
            {children}
          </a>
        );
      }

      return <a href={node.data.uri}>{children}</a>;
    },
  },
  renderText: (text) => {
    return parseTextForTickerSymbol(text);
  },
};

interface Props {
  document: Document;
  overrideOptions?: Options;
  unlink?: Boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const RichText: React.FC<Props> = (props) => {
  if (!props.document || !props.document.content) {
    return <> </>;
  }
  return (
    <div style={props.style} className={props.className && props.className}>
      {props.unlink
        ? documentToReactComponents(props.document, {
            renderNode: {
              ...OPTIONS.renderNode,
              ...(props?.overrideOptions?.renderNode || {}),
            },
          })
        : documentToReactComponents(props.document, {
            renderNode: {
              ...OPTIONS.renderNode,
              ...(props?.overrideOptions?.renderNode || {}),
            },
            renderText: OPTIONS.renderText,
          })}
    </div>
  );
};
