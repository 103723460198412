import React from "react";
import { FAQDocument } from "@tiicker/util/lib/contentful/types";
import Accordion, { ClassNameOptions } from "components/Accordion/Accordion";
import { RichText } from "components/Contentful/RichText";
import { Entry } from "contentful";
import styles from "./Faq.module.scss";
import classNames from "classnames";

interface Props {
  faqs: Entry<FAQDocument>[];
  activeFAQ?: string;
  containerClassName?: string;
}

const Faq = (props: Props) => {
  const activeFAQKey = props.activeFAQ ? `faq-${props.activeFAQ}` : undefined;

  const classOptions: ClassNameOptions = {
    container: classNames(
      styles.HomeFaq,
      props.containerClassName && props.containerClassName
    ),
    panel: styles.HomeFaq__panel,
    openPanel: styles.HomeFaq__panel__open,
    outerTitle: styles.HomeFaq__title,
    innerTitle: styles.HomeFaq__innerTitle,
    loadingTitle: styles.HomeFaq__loadingTitle,
    toggle: styles.HomeFaq__toggle,
    toggleOpen: styles.HomeFaq__toggle__open,
    content: styles.HomeFaq__content,
  };

  return (
    <Accordion
      singleOpen
      classNameOptions={classOptions}
      overriddenSelectedKey={activeFAQKey}
      scrollToKey={activeFAQKey}
    >
      {props.faqs.map((faq) => (
        <Accordion.Panel
          data-href="abc"
          key={`faq-${faq.sys.id}`}
          title={faq.fields.question}
        >
          <RichText
            className={styles.HomeFaq__innerContent}
            document={faq.fields.answer}
          />
        </Accordion.Panel>
      ))}
    </Accordion>
  );
};

export default Faq;
