import { ContentSection } from "@tiicker/util/lib/contentful/types";
import { Entry } from "contentful";
import Lottie from "lottie-react";
import styles from "./HomeHowItWorks.module.scss";

type Props = {
  content: Entry<ContentSection>;
  includes: any;
};

const HowItWorksBullet = (props: Props) => {
  return (
    <div className={styles.howItWorksBullet__container}>
      <div className={styles.howItWorksBullet__imageWrapper}>
        {props.content.fields.json ? (
          <Lottie
            autoPlay
            loop
            className={styles.howItWorksBullet__image}
            animationData={props.content.fields.json}
          />
        ) : (
          <img
            src={props.content.fields.image?.fields.file.url}
            className={styles.howItWorksBullet__image}
          />
        )}
      </div>
      <p className={styles.howItWorksBullet__text}>
        {props.content.fields.displayTitle}
      </p>
    </div>
  );
};

export default HowItWorksBullet;
