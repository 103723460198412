import { InsightDocument } from "@tiicker/util/lib/contentful/types";
import { Asset, Entry } from "contentful";
import InsightCard from "../InsightCard/InsightCard";
import styles from "./InsightsMosaic.module.scss";

interface Props {
  insights: Entry<InsightDocument>[];
  includes: { Asset: Asset[] };
}

const InsightsMosaic = (props: Props) => {
  return (
    <div className={styles.InsightsMosaic__cardsContainer}>
      {props.insights.map((insight, idx) => {
        const type = idx === 0 ? "row" : idx === 1 ? "column" : "block";

        return (
          <InsightCard
            key={`insight-list-${insight.sys.id}`}
            type={type}
            insight={insight}
            includes={props.includes}
            showDate
            className={
              type === "row"
                ? styles.row
                : type === "column"
                ? styles.column
                : styles.block
            }
          />
        );
      })}
    </div>
  );
};

export default InsightsMosaic;
