import React, { ReactElement } from "react";
import { InlineTableDocument } from "@tiicker/util/lib/contentful/types";
import styles from "./ContentfulInlineTable.module.scss";
import SmallDataTable from "components/SmallDataTable/SmallDataTable";

interface Props {
  contentfulTable: InlineTableDocument;
}

export default function ContentfulInlineTable({
  contentfulTable,
}: Props): ReactElement {
  return (
    <div className={styles.tableWrapper}>
      {contentfulTable.displayTitle && (
        <p className={styles.tableTitle}>{contentfulTable.dataTableTitle}</p>
      )}
      <SmallDataTable
        displayOptions={{ isResponsive: true, firstRowIsHeader: true }}
        data={contentfulTable.tableData.tableData}
      />
      {contentfulTable && (
        <p className={styles.tableCitation}>{contentfulTable.citation}</p>
      )}
    </div>
  );
}
