import React from "react";
import styles from "./LogoSlider.module.scss";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/autoplay";

type Props = {
  images?: string[];
};

const LogoSlider = (props: Props) => {
  if (!props.images || props.images.length === 0) {
    return <></>;
  }

  return props.images.length === 1 ? (
    <div className={styles.logoSlider}>
      <div className={styles.logoContainer}>
        <img src={props.images[0]} className={styles.logo} />
      </div>
    </div>
  ) : (
    <div className={styles.logoSlider}>
      <Swiper
        modules={[Autoplay]}
        centeredSlides
        slidesPerView={1}
        loop
        autoplay={{
          delay: 2500,
        }}
      >
        {props.images.map((image, i) => (
          <SwiperSlide key={`image-slider-${i}`}>
            <div className={styles.logoContainer} key={`image-slider-${i}`}>
              <img src={image} className={styles.logo} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default LogoSlider;
