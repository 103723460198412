import classNames from "classnames";
import React, { ReactElement } from "react";
import styles from "./SmallDataTable.module.scss";

type DisplayOptions = {
  isTwoColumnKeyValue?: boolean;
  isResponsive?: boolean;
  firstRowIsHeader?: boolean;
};

interface Props {
  data: string[][];
  citation?: string;
  displayOptions?: DisplayOptions;
}

export default function SmallDataTable({
  data,
  displayOptions,
}: Props): ReactElement {
  const options = displayOptions || {};

  const headers = options.firstRowIsHeader ? data[0] : [];
  const body = options.firstRowIsHeader ? data.slice(1) : data;

  return (
    <div
      className={classNames(
        options.isResponsive && styles.tableWrapper__responsive
      )}
    >
      <table
        className={classNames(
          styles.table,
          options.isTwoColumnKeyValue && styles.table__two_col,
          options.isResponsive && styles.table__responsive
        )}
      >
        {headers.length > 0 && (
          <thead>
            <tr>
              {headers.map((header, i) => (
                <th className={styles.headerCell} key={`header-${i}`}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {body.map((lineItem, i) => {
            return (
              <tr className={styles.dataRow} key={`row-${i}`}>
                {lineItem.map((column, j) => {
                  return (
                    <td className={styles.dataCell} key={`column-${i}-${j}`}>
                      {column}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
