import React from "react";
import { FAQDocument } from "@tiicker/util/lib/contentful/types";
import { Entry } from "contentful";
import styles from "./HomeFAQ.module.scss";
import Faq from "components/FAQ-V2/Faq";
import Button from "components/core/Button/Button";

type Props = {
  content: Entry<FAQDocument>[];
  includes?: any;
  header?: string;
  activeFAQ?: string;
  hideViewAll?: boolean;
};

const HomeFAQ = (props: Props) => {
  const allFaqs = props.content ? props.content : undefined;

  if (!allFaqs || allFaqs.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className={`container ${styles.HomeFAQ__container}`}>
        <div className={styles.HomeFAQ}>
          <div className={styles.HomeFAQ__title}>
            <h2>{props.header}</h2>
          </div>

          <Faq faqs={allFaqs} activeFAQ={props.activeFAQ} />
          {!props.hideViewAll && (
            <Button href="/faq" type="secondary">
              View All FAQs
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default HomeFAQ;
