import {
  ContentSection,
  PageSection,
} from "@tiicker/util/lib/contentful/types";
import { Entry } from "contentful";
import React from "react";
import HowItWorksBullet from "./HowItWorksBullet";
import styles from "./HomeHowItWorks.module.scss";
import Button from "components/core/Button/Button";
import classNames from "classnames";
import { useAuth } from "source/hooks";
import Link from "next/link";
import { useUser } from "components/UserContext/UserContext";

type Props = {
  howItWorks: Entry<PageSection>;
  content: Entry<ContentSection>[] | undefined;
  includes?: any;
  joinNow?: boolean;
};

const HomeHowItWorks = (props: Props) => {
  const auth = useAuth();
  const userContext = useUser();

  return (
    <>
      <div className={classNames("container", styles.howItWorks__container)}>
        {props.howItWorks && (
          <div className={styles.howItWorks__titleContainer}>
            <h2 className={styles.howItWorks__title}>
              {props.howItWorks.fields.displayTitle}
            </h2>
            <p className={styles.howItWorks__body}>
              {props.howItWorks.fields.description}
            </p>
          </div>
        )}
        <div className="row">
          {props.content?.map((bullet, i) => {
            return (
              <div
                key={`how-it-works-${bullet.sys.id}`}
                className={`col-md-3 col-6 ${styles.howItWorks__bullet} ${
                  i % 2 === 1 ? styles.howItWorks__bulletMargin : ""
                }`}
              >
                <HowItWorksBullet content={bullet} includes={props.includes} />
              </div>
            );
          })}
        </div>
        <div className={styles.howItWorks__buttonContainer}>
          {!props.joinNow || userContext.state.userId ? (
            <Link href="/how-it-works">
              <Button arrow>Learn More</Button>
            </Link>
          ) : (
            <Button onClick={() => auth.smsSignUp()} arrow>
              Join Now
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default HomeHowItWorks;
