import {
  ContentSection,
  PageSection,
} from "@tiicker/util/lib/contentful/types";
import { Asset, Entry } from "contentful";
import styles from "./HomeBrandSearch.module.scss";
import React, { useState } from "react";
import SearchBox from "components/Search/SearchBox";
import Link from "next/link";
import { getImageReference } from "@tiicker/util/lib/contentful/helpers";
import { compact } from "lodash";
import PopularBrands from "components/Search/PopularBrands/PopularBrands";
import Container from "components/core/Layout/Container/Container";
import { Media } from "components/Media/media";

type Props = {
  brandSearch: Entry<PageSection>;
  content: Entry<ContentSection>[] | undefined;
  includes?: any;
};

const HomeBrandSearch = (props: Props) => {
  const content = props.content && props.content[0];
  const files = content?.fields.files || [];
  const brands = content?.fields.brands || [];

  const images = compact(
    files.map((x) => getImageReference(x, props.includes))
  );

  return (
    <div className={styles.searchContainer}>
      <div className="container">
        <div className={styles.BrandSearch}>
          {images.slice(0, 12).map((x, i) => {
            return (
              <Link
                key={`brand-logo-${x.fields.file.fileName}`}
                href={`/${x.fields.description}`}
              >
                <a className={styles.BrandSearch__logoContainer}>
                  <img
                    src={x.fields.file.url}
                    className={styles.BrandSearch__mediumLogo}
                  />
                </a>
              </Link>
            );
          })}
          <div className={styles.BrandSearch__searchContainer}>
            <SearchBox
              specifiedSearch="Brand"
              brands={brands}
              inputClass={styles.input}
              removePopular
            />
          </div>
        </div>
      </div>
      <Media greaterThanOrEqual="md">
        <Container>
          <PopularBrands brands={brands} />
        </Container>
      </Media>
      <Media lessThan="md">
        <Container openSide="right">
            <PopularBrands brands={brands} />
        </Container>
      </Media>
    </div>
  );
};

export default HomeBrandSearch;
