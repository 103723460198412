import React from "react";
import styles from "./Card.module.scss";
import Button from "components/core/Button/Button";
import classNames from "classnames";
import Link from "next/link";
import LogoSlider from "./LogoSlider";

type CardProps = {
  layout?: "row" | "column" | "block" | "halfColumn" | "row-reversed";
  image?: string;
  badge?: string;
  title: string;
  className?: string;
  url: string;
  hideButton?: boolean;
  date?: string;
  description?: string;
  sliderImages?: string[];
  featuredCard?: boolean;
  fullHeight?: boolean;
};

const NonBreakingHyphen = '\u2011';

const Card = (props: CardProps) => {
  const layoutClassNames = {
    [styles.Card__cardLayoutRow]: props.layout === "row",
    [styles.Card__cardLayoutRowReversed]: props.layout === "row-reversed",
    [styles.Card__cardLayoutColumn]: props.layout === "column",
    [styles.Card__cardLayoutBlock]: props.layout === "block",
    [styles.Card__cardLayoutHalfColumn]: props.layout === "halfColumn",
    [styles.fullHeightCard]: props.fullHeight,
  };

  const wrapClassNames = classNames(
    styles.Card,
    layoutClassNames,
    props.className
  );

  return (
    <Link href={props.url ? `${props.url}` : "/insights"}>
      <a className={wrapClassNames} href={`${props.url}`}>
        {props.image && props.image !== "" && (
          <img src={props.image} className={styles.img} />
        )}
        {props.image === "" && <div className={styles.emptyImage} />}
        <div
          className={classNames(styles.Card__cardContent, {
            [styles.Card__cardContentLogoSlider]: props.layout === "halfColumn",
            [styles.featuredCard]:
              props.featuredCard && props.layout !== "halfColumn",
            [styles.featuredCardHalfColumn]:
              props.featuredCard && props.layout === "halfColumn",
          })}
        >
          {(props.badge || props.date) && (
            <div className={styles.Card__details}>
              {props.badge && (
                <span className={styles.Card__badge}>{props.badge.replace('-', NonBreakingHyphen)}</span>
              )}
              {props.date && (
                <h4 className={styles.Card__date}>{props.date}</h4>
              )}
            </div>
          )}
          {props.featuredCard && props.layout === "halfColumn" ? (
            <h2 className={classNames(styles.title, styles.halfColumnTitle)}>
              {props.title}
            </h2>
          ) : (
            <h3
              className={classNames(styles.title, {
                [styles.largeTitle]:
                  props.featuredCard && props.layout !== "halfColumn",
              })}
            >
              {props.title}
            </h3>
          )}
          {props.description && (
            <p className={styles.description}>{props.description}</p>
          )}
          {!props.hideButton && (
            <Button
              noAnimate
              className={styles.Card__read}
              type="inline"
              arrow
              noATag
            >
              Read Article
            </Button>
          )}
        </div>
        {props.layout === "halfColumn" && (
          <LogoSlider images={props.sliderImages} />
        )}
      </a>
    </Link>
  );
};

export default Card;
