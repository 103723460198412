import React from "react";
import styles from "./ChooseYourDevice.module.scss";
import Button from "components/core/Button/Button";
import { Entry } from "contentful";
import {
  ContentSection,
  PageSection,
} from "@tiicker/util/lib/contentful/types";
import { getImageReference } from "@tiicker/util/lib/contentful/helpers";
import Lottie from "lottie-react";

type Props = {
  entry: Entry<PageSection>;
  content: Entry<ContentSection>[];
  includes?: any;
};

const ChooseYourDevice = (props: Props) => {
  const title = props.entry.fields.displayTitle;
  const description = props.entry.fields.description;

  const image = getImageReference(props.entry.fields.image, props.includes);

  const lottieJSON = props.content[0]?.fields.json;

  if (!title || !description) {
    return <></>;
  }

  if (lottieJSON) {
    return (
      <ChooseYourDeviceUI
        title={title}
        description={description}
        type="lottie"
        lottieJSON={lottieJSON}
      />
    );
  }

  if (!image) {
    return <></>;
  }

  return (
    <ChooseYourDeviceUI
      title={title}
      description={description}
      type="image"
      image={image.fields.file.url}
    />
  );
};

type UIProps = {
  title: string;
  description: string;
} & ({ type: "image"; image: string } | { type: "lottie"; lottieJSON: any });

const ChooseYourDeviceUI = (props: UIProps) => (
  <div className={styles.ChooseYourDevice}>
    <div className="container">
      <div className={styles.ChooseYourDevice__content}>
        {props.type === "lottie" ? (
          <Lottie animationData={props.lottieJSON} loop />
        ) : (
          <img className={styles.ChooseYourDevice__image} src={props.image} />
        )}
        <div className={styles.ChooseYourDevice__headerContainer}>
          <h2 className={styles.ChooseYourDevice__header}>{props.title}</h2>
          <p className={styles.ChooseYourDevice__subHeader}>
            {props.description}
          </p>
          <Button
            className={styles.ChooseYourDevice__CTAButton}
            type="secondary"
            href="/app"
          >
            Explore the App
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default ChooseYourDevice;
