import { getImageReference } from "@tiicker/util/lib/contentful/helpers";
import { PerkDocument } from "@tiicker/util/lib/contentful/types";
import PerkMegaModal from "components/Perks/PerkMegaModal/PerkMegaModal";
import { Entry } from "contentful";
import { useState } from "react";
import styles from "./FeaturedPerkTile.module.scss";

type Props = {
  perk: Entry<PerkDocument>;
  includes: any;
  perkOfTheWeek?: boolean;
};

const FeaturedPerkTile = (props: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      {modalIsOpen && (
        <PerkMegaModal
          perkId={props.perk.sys.id}
          ticker={props.perk.fields.brand.fields.tickerSymbol}
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
        />
      )}
      <div
        onClick={() => setModalIsOpen(true)}
        className={styles.FeaturedPerkTile}
      >
        <div className={styles.FeaturedPerkTile__imageContainer}>
          <img
            src={(props.perk.fields.image as any).fields.file.url}
            className={styles.FeaturedPerkTile__image}
          ></img>
          {/*TODO: Uncomment this if we decide to have the hero badge again or erase around October 2023*/}
          {/*{props.perkOfTheWeek && (
            <p className={styles.FeaturedPerkTile__featuredPill}>
              Perk Of The Week
            </p>
          )}*/}
        </div>
        <div className={styles.FeaturedPerkTile__textContainer}>
          <p className={styles.FeaturedPerkTile__title}>
            {props.perk.fields.title}
          </p>
          <img
            className={styles.FeaturedPerkTile__logo}
            src={
              getImageReference(
                props.perk.fields.brand.fields.companyLogoDark,
                props.includes
              )?.fields.file.url
            }
          />
        </div>
      </div>
    </>
  );
};

export default FeaturedPerkTile;
